<template>
  <div v-if="!loading" class="flex-container non-scrollable">
    <div>
      <div class="flex align-items-center pb-3 gap-3">
        <NameHeader :independent="porte" :backward-button="false"/>
      </div>
      <div class="flex flex-row gap-3">
        <div class="flex flex-column md:flex-row md:align-items-center">
          <label class="text-lg font-bold line-height-3 mb-2 xfl:mb-0 xfl:mr-2">Fiche mission :</label>
          <Dropdown ref="missionDropdown" v-if="missionsIndependent" v-model="missionSelected" :options="missionsIndependent"
                    class="mb-2 xxl:mb-0 xxl:mr-3" style="width: auto;" @change="(value) => initMission(value.value.id)">
            <template #value="slotProps">
              <span>
                {{ slotProps.value?.clientName }} -
                {{ slotProps.value?.startingDate ? new Date(slotProps.value?.startingDate).toLocaleDateString() : 'Non définie' }}
              </span>
            </template>
            <template #option="slotProps">
              <div>{{ slotProps.option?.clientName }} -
                {{ slotProps.option?.startingDate ? new Date(slotProps.option?.startingDate).toLocaleDateString() : 'Non définie' }}
              </div>
            </template>
          </Dropdown>
        </div>
        <div>
          <Button label="Voir le contrat" class="p-button-rounded" :disabled="!mission?.last_document_contract"
                  size="small" @click="downloadContractFile"/>
        </div>
      </div>
    </div>
    <div class="scrollable mt-2">
      <fiche-mission
          ref="mission"
          :key="missionKeyComp"
          :mission="mission"
          :missions-independent="missionsIndependent.data"
          :editable="false"
          :independentId="this.porte.id"
          enableMissionCreation
          @updateMissionsIndepent="setMissionIndependent"
          @changeMission="setMission"
      />
      <div class="spacer"/>
    </div>
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import FicheMission from "@/components/FicheMission";
import NameHeader from "@/components/NameHeader";
import {mapState} from "vuex";
import {setDateWithoutHours} from "@/utils/Date";

export default {
  name: "FichesMissions",
  components: { FicheMission, NameHeader },
  data () {
    return {
      loading: true,
      mission: null,
      missionSelected: null,
      missionsIndependent: [],
      porte: null,
      missionKeyComp: 0
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me
    })
  },
  async created () {
    this.loading = true
    this.porte = (await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id))
    await this.setMissionIndependent()
    await this.initMission()
    this.loading = false
  },
  methods: {
    async initMission (missionId = null) {
      await this.setMission(missionId ?? this.missionsIndependent[0].id)
      this.missionSelected = JSON.parse(JSON.stringify({
        id: this.mission.id,
        clientName: this.mission?.clientContract?.name,
        startingDate: this.mission?.jsonMission?.startingDate ? setDateWithoutHours(this.mission?.jsonMission?.startingDate) : null,
      }))
    },
    save () {
      this.$refs.mission.saveDraftMission()
    },
    async setMissionIndependent () {
      this.missionsIndependent = (await this.$store.dispatch('mission/getMissionsByIndependent', {id: this.porte.id })).map((mission) => {
        return {
          id: mission.id,
          clientName: mission?.clientContract?.name,
          startingDate: mission?.jsonMission?.startingDate ? setDateWithoutHours(mission?.jsonMission?.startingDate) : null,
        }
      })
      ++this.missionKeyComp
    },
    async setMission (id) {
      this.mission = await this.$store.dispatch('mission/getOneMission', id)
      ++this.missionKeyComp
    },
    async downloadContractFile() {
      let fileO = this.mission.last_document_contract.document
      var file = await this.$store.dispatch('file/downloadFile', fileO)
      const blob = new Blob([new Uint8Array(file.data.data)], {type: file.mimetype})
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    }
  }
}
</script>

<style scoped>

</style>
